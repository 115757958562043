.container {
  padding: 40px;
  background-color: #d2d8db;
}
.slick-slide img {
  margin: auto;
  max-height: 100%;
  margin-bottom: 2.5vh;
  max-width: 100%;
}
 .slick-slide div {
   height: 85vh;
   overflow: hidden;
   overflow-y: scroll;
   padding: 0 10% 0 10%;
 }
.slick-slide h1,h4 {
  margin: 0;
  color: #223843;
}
.slick-slide a h1,h4{
  color: #60aed6;
  text-shadow: 1px 1px 1px #223843;
}
.slick-slide h4 {
  font-style: italic;
}
 .slick-slide p {
   font-size: 80%;
   margin-bottom: 0.2em;
   margin: auto;
   text-align: left;
 }
 .slick-slide ul {
   text-align: left;
   font-size: 70%;
 }
 .tech-stack {
   text-align: left;
 }
 .ts-header {
   color: 223843;
  border-bottom: 2px solid 223843;
  font-weight: 700;
 }
.project-hr {
  width: 70%;
  border-color: #60aed6;
  margin: 1em auto 1em auto;
}
.port-current {
  margin: auto;
}

.slick-slide a:visited, a:link {
  color: #60aed6;
}
.slick-next, .slick-prev {
  z-index: 2;
}
.slick-next:before, .slick-prev:before {
  font-size: 10vh;
}
.slick-next {
  right: 5vh;
}

.portfolio-github-link h1:hover {
  color: #223843;
}
h4 .port-link:hover {
  color: #223843;

}
.port-details {
  background-color: #a0c5d8;
  border-radius: 40px;
  padding: 1em;
}
#gamematch .demo-link, #dmpt .demo-link {
  color: #1b74a2;
}
#gamematch .demo-link :hover, #dmpt .demo-link :hover {
  color: #223843;
  text-decoration: underline;
}
