* {
  scrollbar-width: none
}

a {
  outline: 0;
}

.App {
  text-align: center;
  background-color: #a0c5d8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(5px + 2vmin);
  color: #223843;
}

button:focus {
  outline: 0;
}

div:focus {
  outline: 0;
}

.top-bar {
  position: sticky;
  top: 0;
  left: 0;
  height: 3vw;
  min-height: 30px;
  background-color: #223843;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
}

.my-name {
  position: absolute;
  left: 1vw;
  font-size: 130%;
  font-weight: 700;
  font-style: italic;
  letter-spacing: 0.25vw;
  color: #edf0f2;
}

.navButtons {
  width: 25%;
  height: 100%;
  border-style: none;
  background-color: #223843;
  color: #60aed6;
  font-weight: 700;
  font-size: 2.2vw;
  -webkit-transition: 1s all;
  -ms-transition: 1s all;
  transition: 1s all;
}

.navButtons:hover {
  text-shadow: 1px 1px 5px #a0c5d8;
  cursor: pointer;
}

a:link, a:visited {
  color: #223843;
  text-decoration: none;
}

@import './resume/resume.css';
@import './portfolio/portfolio.css';

.top-bar+.resume-page {
  padding-bottom: 24px;
}

.res-description {
  margin: 0 12px;
}

.res-description+p {
  margin: 12px;
}

@media only screen and (max-width: 768px) {
  /* For mobile displays: */
  * {
    font-size: 1em;
  }
  .section {
    width: 90%;
    min-width: 300px;
    /* max-height: 50vh; */
    height: initial;
    font-size: 1em;
    margin: 0.5em 1em 0.5em 1em;
    padding: 0.5em 1em 0.5em 1em;
  }
  .slick-slide img {
    max-width: 90%;
    max-height: 60vh;
    width: auto;
    height: auto;
  }
  .slick-slide ul, p {
    font-size: 1em !important;
  }
  .slick-next:before, .slick-prev:before {
    font-size: 5vh;
  }
  .slick-next {
    right: 0;
  }
  .slick-slide h1 {
    font-size: 3em;
  }
  .slick-slide h4 {
    font-size: 1em;
  }
}