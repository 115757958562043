.resume-page {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
}
.sec-half {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}
@media screen and (max-width: 769px) {
  .sec-half {
    width: 100%;;
  }
}

.section {
  height: 20vw;
  width: 28vw;
  min-width: 350px;
  font-family: 'Lato', sans-serif;
  font-size: 1.2vw;
  text-align: left;
  border-radius: 20px;
  border-style: groove;
  border-color: #223843;
  background-color: #d2d8db;
  margin: 1em 0.2vw 0 0.2vw;
  box-shadow: 5px 5px  rgba(34,56,67,0.6);
  padding: 0.5em 1em 0.5em 1em;
}

.wider {
  width: 45vw;
  height: initial;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;

}
::-webkit-scrollbar {
  width: 0px; 
  background: #223843;
}

.contact-box {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}

.section i {
  width: 20px;
  margin-right: 20px;
  text-align: left;
  padding: 0;
}
.section h3 {
  margin: 8px;
}
.contact-cent {
  text-align: center;
  margin: 0.2em 0 0 0;
  text-shadow: 1px 1px 3px #60aed6;
  color: #223843;
}

.heading {
  text-align: center;
  margin: 0;
  color: #223843;
  text-shadow: 1px 1px 3px #60aed6;

}

.sub-heading {
  color: #223843;
  border-bottom: 2px solid #60aed6;
  text-shadow: 0.5px 0.5px 1px #60aed6;

  margin: 0.5em 5vw 0.3em 1.5vw;
  font-size: 110%;
}
.skill-list {
  display: flex;
  flex-wrap: wrap;

}
.skill-item {
  background-color: #60aed6;
  color: black;
  font-weight: 700;
  margin: 0.2em;
  padding: 0.2em 0.2em 0 0.2em;
  border-radius: 0.2em;
  font-size: 80%;
}
.right-side {
  float: right;
  font-size: 70%;
}

.sub-sub-heading {
  margin: 0.5em 3vw 0.3em 1vw;
  color: #223843;
  text-shadow: 0.2px 0.2px 1px #60aed6;

}
.res-description {
  margin: 0;
}

.exp-hr {
  border-color: #60aed6;
  margin-bottom: 1.5em;
}

.printable {
  font-weight: 700;
  background-color: #223843;
  padding: 0.3em;
  border-radius: 5px;
  font-size: 1.5vw;
  color: #60aed6 !important;
  -webkit-transition: 1s all;
  -ms-transition: 1s all;
  transition: 1s all;
}
.printable:hover {
  background-color: black;
}
.mini-heading {
  margin: 0.5em 0 0 -1em;
  font-size: 90%
}

a li:hover, a strong:hover {
  color: #60aed6;
}
a li em {
  font-size: 90%;
}
.portfolio-link {
  margin: 0.5em 0 2vw 33%;
}
.portfolio-link a:hover {
  color: #60aed6;
}
